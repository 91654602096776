/* Add this CSS to your stylesheet */
.banner-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.banner-image.active {
  opacity: 1;
}

.bg-green-600 {
  background-color: #16a34a;
}

.bg-blue-600 {
  background-color: #3b82f6;
}

.text-white {
  color: #ffffff;
}

.rounded-full {
  border-radius: 9999px;
}

.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hover\:bg-green-700:hover {
  background-color: #15803d;
}

.hover\:bg-blue-700:hover {
  background-color: #2563eb;
}

/* Add this custom CSS in your styles */
.gradient-text {
  background: linear-gradient(90deg, #7ced6a, #f21014);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes pumping {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.animate-pumping {
  animation: pumping 1s infinite;
}
